import { IconCloseRed, IconDone } from 'assets';
import { Button, Input } from 'components';
import API from 'config/Axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import Lottie from 'lottie-react';
import ICLottieError from 'assets/icon/ic-error.json';
import ICLottieCheckin from 'assets/icon/ic-checkin.json';
import { useRef } from 'react';
import useOutsideClick from 'Hooks/useOutsideClick/useOutsideClick';

export default function CheckinPasien() {
  const [tokenCheckin, setTokenCheckin] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [statusCheckin, setStatusCheckin] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [debounceValueCheckIn] = useDebounce(tokenCheckin, 500);
  const inputRef = useRef(null);

  useOutsideClick(inputRef, () => {
    inputRef.current?.focus();
  });

  const handleClickCheckin = async () => {
    setIsLoading(true);
    const sendData = {
      checkin_token: tokenCheckin,
    };

    await API.patch('/staff/queues/checkin', sendData)
      .then((res) => {
        setStatusCheckin(res);
        setIsLoading(false);
        setShowModal(true);
      })
      .catch((err) => {
        setStatusCheckin(err.response);
        setIsLoading(false);
        setShowModal(true);
      })
      .finally(() => {
        setTokenCheckin('');
        inputRef.current?.focus();
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      });
  };

  useEffect(() => {
    if (!!tokenCheckin.length) {
      handleClickCheckin();
    }
  }, [debounceValueCheckIn]);

  return (
    <div
      style={{ background: '#CCE7FF', padding: 16 }}
      className="flex flex-col h-screen"
    >
      <div className="bg-white pt-11 pb-8 px-9 h-full">
        {/* START : Header Content */}
        <div className="content flex justify-center border-b-2 pb-10 mb-8 border-gray2">
          <div className="font-inter">
            <h2 className="font-bold text-3xl text-black2">
              Scan Barcode Pasien
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center ">
          <div className="w-4/6">
            <Input
              disabled={isLoading}
              ref={inputRef}
              autoFocus={true}
              withOutline
              name="Token Checkin"
              label="Token Checkin"
              className="mb-6"
              value={tokenCheckin}
              onChange={(e) => setTokenCheckin(e.target.value)}
            />
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-40">
          <div
            className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
            style={{ width: 512, height: 450 }}
          >
            {statusCheckin.status === 404 && (
              <>
                <div className="w-20 h-20">
                  <Lottie animationData={ICLottieError} loop={true} />
                </div>

                <p className="font-inter font-bold text-2xl my-8 capitalize text-center">
                  <span className="text-red1">Oops !&nbsp;</span>
                  {statusCheckin.data.message}
                </p>
              </>
            )}

            {statusCheckin.status === 200 && (
              <>
                <div className="w-20 h-20">
                  <Lottie animationData={ICLottieCheckin} loop={true} />
                </div>

                <p className="font-inter font-bold text-2xl my-8 capitalize text-center">
                  {statusCheckin.data.message}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
