import { tambahRiwayatVaksinasi } from 'config/Redux/action';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Input, Modal } from '..';
import Button from '../Button';

export default function RiwayatVaksinasi({ closeModal, value, idPasien }) {
  const [showModal, setShowModal] = useState(false);
  const [dataVaksin, setDataVaksin] = useState('');
  const [date, setDate] = useState('');
  const [periode, setPeriode] = useState('');
  const [sideEffect, setSideEffect] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [response, setResponse] = useState('');
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const handleClick = async () => {
    setShowLoading(true);
    let vaksin = [];
    dataVaksin.vaccine_types.map((item, i) => {
      vaksin.push({
        id: item.id,
      });
    });

    let sendData = {};
    sendData.family_member_id = idPasien;
    sendData.vaccine_id = dataVaksin.id;
    sendData.date_taken = date;
    sendData.side_effect = sideEffect;
    sendData.vaccine_types = vaksin;

    const send = await dispatch(
      tambahRiwayatVaksinasi('/staff/vaccinations', sendData)
    );

    setResponse(send);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20">
      <div
        className="w-auto max-h-full bg-white px-12 py-12 relative flex flex-col justify-center items-center"
        style={{ width: 420, height: 673 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>
        <h4 className="font-inter font-semibold text-base mb-8 w-full">
          Riwayat Vaksinasi
        </h4>

        <div className="w-full">
          <Input
            withOutline
            label="Tanggal Vaksin"
            type="date"
            name="tgl-vaksinasi"
            className="mb-8"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <Input
            id="vaksin"
            withOutline
            label="Vaksin"
            type="text"
            name="vaksin"
            className="flex-1 mb-8"
            value={dataVaksin?.brand_name || ''}
            readOnly
            onClick={() => setShowModal(true)}
          />

          {dataVaksin && (
            <Input
              id="jenis-vaksin"
              withOutline
              label="Jenis Vakisn"
              type="text"
              name="jenis-vaksin"
              className="flex-1 mb-8"
              value={
                dataVaksin?.vaccine_types?.map((item, i) => `${item.name}`) ||
                ''
              }
              readOnly
            />
          )}

          {showModal && (
            <Modal
              type="jenis-vaksin-riwayat"
              closeModal={() => setShowModal(false)}
              value={(data) => {
                setDataVaksin(data);
              }}
            />
          )}

          <Input
            id="efek-samping"
            withOutline
            label="Efek Samping Diderita"
            type="text"
            name="efek-samping"
            className="flex-1 mb-8"
            value={sideEffect}
            onChange={(e) => setSideEffect(e.target.value)}
          />

          <div className="w-full flex justify-center">
            <Button
              isPrimary
              title="Simpan"
              className="rounded-10"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>

      {showLoading && (
        <Modal
          isLoading={isLoading}
          message={`${
            response.status === 200
              ? 'Data Riwayat Vaksinasi Berhasil Disimpan'
              : 'Data Riwayat Vaksinasi Gagal Disimpan'
          }`}
          closeModal={() => {
            setShowLoading(false);
            setDataVaksin('');
            setDate('');
            setPeriode('');
            setSideEffect('');
          }}
        />
      )}
    </div>
  );
}
