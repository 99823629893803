import React, { useState } from 'react';

export const InputOutline = React.forwardRef(
  (
    {
      id,
      type,
      name,
      label,
      className,
      onFocus,
      onBlur,
      autoFocus,
      onChange,
      onClick,
      value,
      placeholder,
      readOnly,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <div
        className={`outline relative border border-black focus-within:border-blue1 focus-within:text-blue1 rounded-3 ${className}`}
      >
        <input
          id={id}
          type={type}
          name={name}
          className={`block p-3 w-full text-sm font-inter appearance-none border-none focus:outline-none focus:ring rounded-3 text-black2 ${
            isActive
              ? 'placeholder-gray1'
              : 'relative z-10 bg-transparent placeholder-transparent'
          }`}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          onChange={onChange}
          onClick={onClick}
          value={value}
          autoFocus={autoFocus}
          autoComplete="off"
          readOnly={readOnly}
          placeholder={placeholder ? placeholder : ' '}
          ref={ref}
          {...props}
        />
        <label
          htmlFor="username"
          className={`absolute top-0 text-sm font-inter bg-white p-3 duration-300 origin-0 rounded-full break-words ${
            isActive ? 'z-10 focus-within:text-blue-600' : '-z-1 text-gray1'
          }`}
        >
          {label}
        </label>
      </div>
    );
  }
);
