import { IconAntrianKosong, IconSearch } from 'assets';
import { Button, Input, LabelStatus, Modal } from 'components/atoms';
import {
  getAllOrders,
  getStatusAntrian,
  getStatusPembayaran,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate, useForm } from 'utils';

export default function DaftarPesanan(props) {
  const [listSearch, setListSearch] = useState('');
  const [search, setSearch] = useState('');
  const [idPasien, setIdPasien] = useState('');
  const [showBatalkan, setShowBatalkan] = useState(false);
  const [listAntrian, setListAntrian] = useState([]);
  const [statusPembayaran, setStatusPembayaran] = useState([]);
  const [statusPembayaranId, setStatusPembayaranId] = useState('');
  const [tglVaksin, setTglVaksin] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const _getListAntrian = async () => {
    const params = {
      period: 'all',
      limit: 25,
      page,
    };

    let date = 'filters[date_plan]';
    let pay = 'filters[payment_status_id]';

    if (search) params.search_keyword = search;
    if (statusPembayaranId) params[pay] = statusPembayaranId?.id;
    if (tglVaksin) params[date] = tglVaksin;

    const list = await dispatch(getAllOrders('/staff/orders', params));
    setListAntrian(list);

    const status = await dispatch(
      getStatusPembayaran('/staff/payment-statuses')
    );
    setStatusPembayaran(status);
  };

  useEffect(() => {
    _getListAntrian();
  }, [search, tglVaksin, statusPembayaranId, page]);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2">
        <div className="font-inter">
          <h2 className="font-bold text-3xl text-black2">Pesanan Vaksinasi</h2>
        </div>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-between mb-6 ">
        <div className="font-inter">
          <h2 className="font-bold text-2xl text-black2">Daftar Pesanan</h2>
        </div>

        <div className="block lg:flex lg:items-center ">
          {/* {localStorage.getItem("role") !== "admin" && (
              <label className="inline-flex items-center mr-7 px-4 rounded-3 py-2 bg-gray2 mb-4 lg:mb-0 w-full lg:w-max">
                <input
                  type="checkbox"
                  className="form-checkbox text-blue1 rounded-3"
                  defaultChecked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <span className="ml-2 text-sm text-black2">
                  Tampilkan Sesuai Tugas
                </span>
              </label>
            )} */}

          <div className="mb-4 lg:mb-0 flex justify- items-center">
            <p className="text-sm font-semibold font-inter mr-5 whitespace-nowrap">
              Filter :{' '}
            </p>

            <Input
              withDropdown
              label="Pembayaran"
              className="mr-5 w-32"
              name="status-pembayaran"
              options={statusPembayaran}
              value={statusPembayaranId ? statusPembayaranId.name : ''}
              onChange={(e) => {}}
              fixValue={async (value) => {
                setPage(1);
                setStatusPembayaranId(value);
              }}
            />
            <Input
              withDate
              // withDateNow
              label="Tanggal"
              className="lg:mr-5 w-32"
              name="tanggal"
              value={tglVaksin}
              fixValue={(value) => {
                setPage(1);
                setTglVaksin(value);
              }}
            />
          </div>

          <Input
            withIcon
            type="text"
            name="search"
            value={search}
            className="px-2 py-1"
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Cari data..."
            icon={IconSearch}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />

          <Button
            title="+ Tambah Antrian"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 mt-4 lg:mt-0  w-full lg:w-max lg:ml-7 hover:bg-blue3 md:mb-3 lg:mb-0 whitespace-nowrap"
            onClick={() =>
              props.history.push('/dashboard/antrian-klinik/tambah-antrian')
            }
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-2/12 py-3 px-8 border border-blue4">
                  ID Order
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  Tanggal
                </th>
                <th className="w-1/12 py-3 px-2 border border-blue4">
                  Layanan
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Pemilik Akun
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Jumlah Pasien
                </th>
                <th className="w-1/12 px-1 py-3  border border-blue4 whitespace-nowrap">
                  Status Pembayaran
                </th>
                <th className="w-4/12  border border-blue4 whitespace-nowrap py-3">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {listAntrian?.length === 0 && (
                <tr className="text-center">
                  <td colSpan={7} className="whitespace-nowrap p-4">
                    <img
                      src={IconAntrianKosong}
                      alt=""
                      className="mx-auto mb-7"
                    />
                    <p className="ml-6">Belum ada antrian baru</p>
                  </td>
                </tr>
              )}
              {listAntrian?.data?.map((list) => (
                <tr className="text-center" key={list.id}>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list.id}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {formatDate(list.date_plan)}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {/* {list.order?.service_location?.name} */}
                    {list.service_location_id === 1 ? 'Klinik' : 'Homecare'}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list.family_member?.fullname || '-'}
                  </td>
                  <td className="border border-blue4 px-4 whitespace-nowrap">
                    {list?.patient_amount}
                  </td>
                  <td className="border border-blue4 px-2 whitespace-nowrap">
                    {/* {list.order?.payment_status?.name} */}
                    <LabelStatus
                      withOutline
                      type={list.payment_status?.name}
                      title={list.payment_status?.name}
                    />
                  </td>

                  <td className="border border-blue4 text-left px-2 py-3 flex justify-center">
                    <Link
                      className="bg-blue1 text-white border rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                      to={`/dashboard/detail-pesanan/${list.id}`}
                    >
                      Detail
                    </Link>

                    <button
                      className="bg-red1 text-white border rounded-3 hover:bg-red-400 font-inter font-medium text-xs px-4 py-2 mb-1"
                      onClick={() => {
                        setIdPasien(list.id);
                        setShowBatalkan(true);
                      }}
                    >
                      Batal
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex p-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">{`Data ${
          listAntrian.from || 0
        } hingga ${listAntrian.to || 0} dari ${listAntrian.total || 0}`}</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <Button
            disabled={page === 1}
            isGreenOutline
            isBtnOutlineSecondary
            title="Prev Page"
            className="mr-2 w-full md:w-max mb-2"
            onClick={() => setPage(page - 1)}
          />
          <Button
            disabled={page === listAntrian.last_page}
            isGreenOutline
            isBtnOutlineSecondary
            title="Next Page"
            onClick={() => setPage(page + 1)}
          />
        </div>
      </div>

      {showBatalkan && (
        <Modal
          idPasien={idPasien}
          type="batalkan-vaksinasi"
          closeModal={() => setShowBatalkan(!showBatalkan)}
          // value={(value) => Antrian(value)}
          tes={() => _getListAntrian()}
        />
      )}
    </div>
  );
}
