import { IconAntrianKosong, IconLoading, IconSearch } from 'assets';
import { Button, Input } from 'components/atoms';
import { Modal } from 'components/atoms/Modal/Modal';
import API from 'config/Axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createPagination } from 'utils/createPagination/createPagination';
import ButtonNew from 'components/atoms/Button/Button';

const PAGE_SIZE = 5;

export const BlockedDates = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);

  const [idBlockedDate, setIdBlockedDate] = useState(null);
  const [listBlockedDates, setListBlockedDates] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);

  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [resultPagination, setResultPagination] = useState([]);
  const numberOfData = PAGE_SIZE * page;

  const onSearchBlockedDates = (e) => {
    const value = e.target.value;
    const filteredBlockedDates = blockedDates?.filter((item) => {
      return (
        item.information.toLowerCase().match(value.toLowerCase()) ||
        item.type.toLowerCase().match(value.toLowerCase()) ||
        item.date.toLowerCase().match(value.toLowerCase())
      );
    });

    setPage(1);
    setKeyword(value);
    setListBlockedDates(filteredBlockedDates);
  };

  const onGetBlockedDates = () => {
    setIsLoading(true);
    API.get('/staff/blocked-dates')
      .then((res) => {
        const dataBlockedDates = res?.data?.blockedDates;
        setListBlockedDates(dataBlockedDates);
        setBlockedDates(dataBlockedDates);
        setTotalData(dataBlockedDates?.length);
      })
      .catch((err) => console.log('err >>', err))
      .finally(() => setIsLoading(false));
  };

  const onEditBlockedDate = (blockedDate) =>
    history.push({
      pathname: '/dashboard/add-blocked-dates',
      state: blockedDate,
    });

  const onDeleteBlockedDate = () => {
    setIsDisabledButton(true);
    API.delete(`/staff/blocked-dates/${idBlockedDate}`)
      .then((res) => {
        setIdBlockedDate(null);
        setIsShowConfirmDelete(false);
        onGetBlockedDates();
      })
      .catch(() => alert('Gagal Menghapus Blocked Date !'))
      .finally(() => setIsDisabledButton(false));
  };

  useEffect(() => {
    onGetBlockedDates();
  }, []);

  useEffect(() => {
    const paginationData = createPagination(listBlockedDates, page, PAGE_SIZE);
    const tempLastPage = Math.ceil(listBlockedDates.length / PAGE_SIZE);
    setTotalPage(tempLastPage);
    setResultPagination(paginationData);
  }, [page, listBlockedDates]);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2">
        <div className="font-inter">
          <h2 className="font-bold text-3xl text-black2">Blocked Dates</h2>
        </div>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-end mb-6 ">
        <div className="block lg:flex ">
          <Input
            withIcon
            type="text"
            name="search"
            value={keyword}
            className="px-2 py-1"
            placeholder="Cari data..."
            icon={IconSearch}
            onChange={onSearchBlockedDates}
          />

          <Button
            title="+ Blocked Date"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 mt-4 lg:mt-0  w-full lg:w-max lg:ml-7 hover:bg-blue3"
            onClick={() => history.push('/dashboard/add-blocked-dates')}
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  Tanggal
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4">Tipe</th>
                <th className="w-2/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Informasi
                </th>
                <th className="w-2/12  border border-blue4 whitespace-nowrap  py-3 px-8">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className="text-center animate-AfterLoading">
                  <td colSpan={6} className="whitespace-nowrap p-4">
                    <img
                      src={IconLoading}
                      alt=""
                      className="mx-auto mb-7 animate-spin w-16 h-16 mt-4"
                    />
                    <p className="animate-pulse">Loading...</p>
                  </td>
                </tr>
              ) : (
                <>
                  {listBlockedDates.length === 0 && !isLoading ? (
                    <tr className="text-center">
                      <td colSpan={6} className="whitespace-nowrap p-4">
                        <img
                          src={IconAntrianKosong}
                          alt=""
                          className="mx-auto my-7"
                        />
                        <p className="ml-6">Belum ada blocked dates</p>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {resultPagination?.map((list) => (
                        <tr className="text-center" key={list.id}>
                          <td className="border border-blue4 p-4 whitespace-nowrap capitalize">
                            {list.date || '-'}
                          </td>
                          <td className="border border-blue4 px-4 whitespace-nowrap capitalize">
                            {list.type || '-'}
                          </td>
                          <td className="border border-blue4 px-4 whitespace-nowrap capitalize">
                            {list.information || '-'}
                          </td>
                          <td className="border border-blue4 px-4 whitespace-nowrap">
                            <div className="flex gap-2 justify-center">
                              <ButtonNew
                                isPrimary
                                onClick={() => onEditBlockedDate(list)}
                              >
                                Ubah
                              </ButtonNew>
                              <ButtonNew
                                isDanger
                                isBordered
                                onClick={() => {
                                  setIdBlockedDate(list?.id);
                                  setIsShowConfirmDelete(true);
                                }}
                              >
                                Hapus
                              </ButtonNew>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex p-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">{`Data ${
          numberOfData - (PAGE_SIZE - 1)
        } hingga ${numberOfData > totalData ? totalData : numberOfData} dari ${
          totalData || 0
        }`}</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <ButtonNew
            isSuccess
            isBordered
            isDisabled={page === 1}
            onClick={() => setPage(page - 1)}
            className="mr-2"
          >
            Prev Page
          </ButtonNew>

          <ButtonNew
            isSuccess
            isBordered
            isDisabled={page === totalPage}
            onClick={() => setPage(page + 1)}
          >
            Next Page
          </ButtonNew>
        </div>
      </div>
      <Modal
        title="Yakin Ingin Menghapus?"
        text=""
        isDisabled={isDisabledButton}
        isOpen={isShowConfirmDelete}
        onClose={() => setIsShowConfirmDelete(false)}
        onSubmit={() => onDeleteBlockedDate()}
      />
    </div>
  );
};
