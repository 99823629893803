import API from 'config/Axios';
import {
  getAllFamilyPostion,
  getJenisVaksin,
  getMerekVaksin,
} from 'config/Redux/action';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateAsc } from 'utils';
import { Input, Modal } from '..';
import Button from '../Button';
import Stepper, { Controller, MainContent, Meta } from '../Stepper';
import ButtonNew from '../Button/Button.jsx';

export default function TambahPasien({ closeModal }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [showTambahDaftarKeluarga, setShowTambahDaftarKeluarga] =
    useState(false);
  const [vaksin, setVaksin] = useState({
    jenis: '',
    merek: '',
  });
  const [idKeluarga, setIdKeluarga] = useState('');
  const [newAccount, setNewAccount] = useState(false);
  const [dataNewAccount, setDataNewAccount] = useState({
    phone_number: '',
    fullname: '',
    gender: '',
    birthdate: '',
    birthplace: 'makassar',
    family_position_id: '',
    sudahVaksin: null,
  });
  const [vaccinationHistory, setVaccinationHistory] = useState(false);
  const [relasiKeluarga, setRelasiKeluarga] = useState({
    allRelasi: null,
    id: null,
    name: null,
  });
  const [dataRiwayatVaksin, setDataRiwayatVaksin] = useState([]);
  const [tempRiwayat, setTempRiwayat] = useState({
    idTemp: new Date().getTime(),
    date_taken: '',
    vaccine_id: '',
    vaccine_merek: '',
    side_effect: '',
    vaccine_types: '',
  });
  const [idjenisVaksin, setIdjenisVaksin] = useState('');
  const [successCreateFamily, setSuccessCreateFamily] = useState(false);
  const [dataSucces, setDataSucces] = useState('');

  const { register, handleSubmit, reset, errors, setValue } = useForm();

  const jenisKelamin = [
    {
      name: 'Laki - Laki',
      value: 'male',
    },
    {
      name: 'Perempuan',
      value: 'female',
    },
  ];

  const isInitialMount = useRef(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    if (e.target.value) {
      setIsDisabled(false);
      return setIdKeluarga(e.target.value);
    }
    return setIsDisabled(true);
  };

  const _relasaFamily = async () => {
    const relasi = await dispatch(
      getAllFamilyPostion('/staff/family-positions')
    );
    setRelasiKeluarga({ ...relasiKeluarga, allRelasi: relasi });
  };

  const _getVaksin = async () => {
    const jenis = await dispatch(getJenisVaksin('/staff/vaccine-types'));
    const merek = await dispatch(getMerekVaksin('/staff/vaccines'));
    setVaksin({ ...vaksin, jenis, merek: merek.vaccines });
    // setTempMerekVaksin(merek.vaccines);
  };

  const [isDisabledBtnSend, setIsDisabledBtnSend] = useState(false);

  const _createNewAccount = () => {
    setIsDisabledBtnSend(true);
    const sendData = {};
    sendData.phone_number = dataNewAccount.phone_number;
    sendData.fullname = dataNewAccount.fullname;
    sendData.gender = dataNewAccount.gender;
    sendData.birthdate = dataNewAccount.birthdate;
    sendData.birthplace = 'makassar';
    sendData.family_position_id = dataNewAccount.family_position_id;

    if (dataNewAccount.sudahVaksin) {
      sendData.vaccination_histories = [];
      if (dataRiwayatVaksin.length > 0 && [{ ...tempRiwayat }].length > 0) {
        const tempData = [...dataRiwayatVaksin, tempRiwayat];
        tempData.map((data, i) => {
          sendData.vaccination_histories.push({
            date_taken: data.date_taken,
            vaccine_id: data.vaccine_id,
            side_effect: data.side_effect,
            vaccine_types: data.vaccine_types?.map((item) => ({ id: item.id })),
          });
        });
      } else if (dataRiwayatVaksin.length) {
        dataRiwayatVaksin.map((data, i) => {
          sendData.vaccination_histories.push({
            date_taken: data.date_taken,
            vaccine_id: data.vaccine_id,
            side_effect: data.side_effect,
            vaccine_types: data.vaccine_types?.map((item) => ({ id: item.id })),
          });
        });
      } else {
        [{ ...tempRiwayat }].map((data, i) => {
          sendData.vaccination_histories.push({
            date_taken: data.date_taken,
            vaccine_id: data.vaccine_id,
            side_effect: data.side_effect,
            vaccine_types: data.vaccine_types?.map((item) => ({ id: item.id })),
          });
        });
      }
    } else {
      sendData.vaccination_histories = [];
    }

    API.post('/staff/family-members', sendData)
      .then((res) => {
        localStorage.setItem('family_id', res.data.familyMember.family_id);
        setNewAccount(false);
        setVaccinationHistory(false);
        setDataSucces(res.data.familyMember);
        setSuccessCreateFamily(true);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => setIsDisabledBtnSend(false));
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      _relasaFamily();
      _getVaksin();
    } else {
      return;
    }
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white flex flex-col py-16 px-12 relative z-30 "
        style={{ width: 420, minHeight: 306 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>
        <h4 className="font-inter font-bold text-black1 text-lg mb-1">
          Tambah Pasien
        </h4>
        <p className="font-normal text-xs text-gray1 mb-11">
          Jika pasien yang akan ditambahkan belum memiliki akun keluarga, maka
          perlu membuat akun baru.
        </p>

        <Button
          isPrimary
          title="Tambahkan di keluarga terdaftar"
          className="rounded-3 mb-7 w-max"
          onClick={() => setShowTambahDaftarKeluarga(true)}
        />

        <div className="flex">
          <p className="font-inter text-sm font-normal">Belum punya akun ?</p>
          <Button
            title="Buat Akun Baru"
            className="rounded-3 mb-7 font-inter text-sm font-bold text-blue1 ml-3"
            onClick={() => setNewAccount(true)}
          />
        </div>

        {/* Modal Create New Account */}
        {newAccount && (
          <div className="fixed inset-0 flex justify-center items-center z-30">
            <div
              className="w-auto max-h-full bg-white flex flex-col justify-center py-16 px-8 relative z-30 "
              style={{ width: 420, minHeight: 306 }}
            >
              <div className="absolute top-5 right-7">
                <svg
                  className="cursor-pointer"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setNewAccount(false)}
                >
                  <path
                    d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>

              <h2 className="font-inter font-semibold text-base mb-6 capitalize">
                Data Untuk akun baru
              </h2>

              <Input
                withOutline
                label="No Telpon"
                name="noHp"
                className="mb-6"
                value={dataNewAccount.phone_number}
                onChange={(e) =>
                  setDataNewAccount({
                    ...dataNewAccount,
                    phone_number: e.target.value,
                  })
                }
                ref={register({
                  required: true,
                })}
              />

              <Input
                withOutline
                label="Nama Lengkap"
                name="nama-lengkap"
                className="mb-6"
                value={dataNewAccount.fullname}
                onChange={(e) =>
                  setDataNewAccount({
                    ...dataNewAccount,
                    fullname: e.target.value,
                  })
                }
                ref={register({
                  required: true,
                })}
              />

              <Input
                withDate
                label="Tanggal Lahir"
                name="tgl-lahir"
                className="mb-6"
                value={dataNewAccount.birthdate}
                onChange={(e) => {
                  setTempRiwayat({
                    ...tempRiwayat,
                    date_taken: e.target.value,
                  });
                }}
                fixValue={(value) =>
                  setDataNewAccount({ ...dataNewAccount, birthdate: value })
                }
                ref={register({
                  required: true,
                })}
              />

              <Input
                withDropdown
                label="Relasi dalam keluarga"
                name="relasi"
                options={relasiKeluarga.allRelasi}
                className="mb-6"
                value={relasiKeluarga.name}
                onChange={(e) => {}}
                fixValue={(value) => {
                  setRelasiKeluarga({
                    ...relasiKeluarga,
                    id: value.id,
                    name: value.name,
                  });
                  setDataNewAccount({
                    ...dataNewAccount,
                    family_position_id: value.id,
                  });
                }}
                ref={register({
                  required: true,
                })}
              />

              <Input
                withDropdown
                options={jenisKelamin}
                label="Jenis kalamin"
                name="jenis-kelamin"
                className="mb-6"
                value={
                  !dataNewAccount.gender
                    ? ''
                    : dataNewAccount.gender === 'male'
                    ? 'Laki - Laki'
                    : 'Perempuan'
                }
                onChange={(e) =>
                  setDataNewAccount({
                    ...dataNewAccount,
                    gender: e.target.value,
                  })
                }
                fixValue={(value) =>
                  setDataNewAccount({
                    ...dataNewAccount,
                    gender: value.value,
                  })
                }
                ref={register({
                  required: true,
                })}
              />

              <div className="font-inter mb-10">
                <span className="text-black font-bold text-sm">
                  Sudah Vaksin
                </span>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio checked:bg-blue1"
                      name="vaksinasi"
                      value={true}
                      checked={dataNewAccount.sudahVaksin}
                      onChange={() =>
                        setDataNewAccount({
                          ...dataNewAccount,
                          sudahVaksin: true,
                        })
                      }
                      ref={register({
                        required: true,
                      })}
                    />
                    <span className="ml-2 text-sm">Sudah</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      className="form-radio checked:bg-blue1"
                      name="vaksinasi"
                      value={false}
                      checked={!dataNewAccount.sudahVaksin}
                      onChange={() =>
                        setDataNewAccount({
                          ...dataNewAccount,
                          sudahVaksin: false,
                        })
                      }
                      ref={register({
                        required: true,
                      })}
                    />
                    <span className="ml-2">Belum</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-center">
                <ButtonNew
                  isPrimary
                  isLoading={isDisabledBtnSend}
                  isDisabled={
                    !dataNewAccount.phone_number ||
                    !dataNewAccount.fullname ||
                    !dataNewAccount.birthdate ||
                    !dataNewAccount.birthplace ||
                    !dataNewAccount.family_position_id ||
                    !dataNewAccount.gender
                  }
                  onClick={
                    dataNewAccount.sudahVaksin
                      ? (e) => setVaccinationHistory(true)
                      : handleSubmit(_createNewAccount)
                  }
                  style={{ width: 'max-content' }}
                >
                  {dataNewAccount.sudahVaksin ? 'Selanjutnya' : 'Daftarkan'}
                </ButtonNew>
              </div>
            </div>
          </div>
        )}

        {vaccinationHistory && (
          <div className="fixed inset-0 flex justify-center items-center z-30 overflow-hidden">
            <div
              className="w-auto h-full bg-white flex flex-col py-16 px-8 relative z-30 overflow-y-scroll scrollbar-none "
              style={{ width: 420 }}
            >
              <div className="absolute top-5 right-7">
                <svg
                  className="cursor-pointer"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setVaccinationHistory(false)}
                >
                  <path
                    d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>

              <h2 className="font-inter font-semibold text-base mb-8">
                Riwayat Vaksinasi
              </h2>

              {dataRiwayatVaksin.length !== 0 && (
                <div className="mb-2">
                  {dataRiwayatVaksin?.map((data, i) => (
                    <div
                      className="font-inter p-3.5 rounded-3 border border-gray2 capitalize mb-4"
                      key={i}
                    >
                      <div className="flex justify-between items-center mb-3 ">
                        <p className="text-xs">
                          {formatDateAsc(data.date_taken)}
                        </p>

                        <div
                          className="cursor-pointer "
                          id={data.idTemp}
                          onClick={(e) => {
                            const hapusData = dataRiwayatVaksin.filter(
                              (data) =>
                                parseInt(data.idTemp) !== parseInt(e.target.id)
                            );

                            return setDataRiwayatVaksin(hapusData);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            id={data.idTemp}
                          >
                            <path
                              id={data.idTemp}
                              d="M9.87818 7.99886L15.606 2.28357C15.8568 2.03271 15.9977 1.69246 15.9977 1.33769C15.9977 0.98291 15.8568 0.642664 15.606 0.391799C15.3552 0.140934 15.015 0 14.6602 0C14.3055 0 13.9653 0.140934 13.7145 0.391799L8 6.12041L2.28552 0.391799C2.03469 0.140934 1.6945 -2.64329e-09 1.33977 0C0.985044 2.64329e-09 0.644846 0.140934 0.394017 0.391799C0.143188 0.642664 0.00227327 0.98291 0.00227327 1.33769C0.00227327 1.69246 0.143188 2.03271 0.394017 2.28357L6.12182 7.99886L0.394017 13.7142C0.269166 13.838 0.17007 13.9853 0.102444 14.1477C0.0348177 14.31 0 14.4842 0 14.66C0 14.8359 0.0348177 15.01 0.102444 15.1724C0.17007 15.3347 0.269166 15.4821 0.394017 15.6059C0.517848 15.7308 0.665174 15.8299 0.827496 15.8975C0.989818 15.9652 1.16392 16 1.33977 16C1.51562 16 1.68972 15.9652 1.85204 15.8975C2.01437 15.8299 2.16169 15.7308 2.28552 15.6059L8 9.87731L13.7145 15.6059C13.8383 15.7308 13.9856 15.8299 14.148 15.8975C14.3103 15.9652 14.4844 16 14.6602 16C14.8361 16 15.0102 15.9652 15.1725 15.8975C15.3348 15.8299 15.4822 15.7308 15.606 15.6059C15.7308 15.4821 15.8299 15.3347 15.8976 15.1724C15.9652 15.01 16 14.8359 16 14.66C16 14.4842 15.9652 14.31 15.8976 14.1477C15.8299 13.9853 15.7308 13.838 15.606 13.7142L9.87818 7.99886Z"
                              fill="#C8C8C8"
                            />
                          </svg>
                        </div>
                      </div>
                      <p className="font-medium text-sm mb-3">
                        {data.vaccine_types?.map((item) => item.name)} - &nbsp;
                        <span className="text-gray1">
                          {' '}
                          {data.vaccine_merek}
                        </span>
                      </p>
                      <p className="text-xs text-gray4">Efek Samping</p>
                      <p className="text-xs ">
                        {data.side_effect === ''
                          ? 'Tidak ada efek samping'
                          : `${data.side_effect}`}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              <Input
                withDate
                label="Tanggal Vaksinasi"
                name="tgl-vaksinasi"
                className="mb-6"
                value={tempRiwayat.date_taken}
                onChange={(e) =>
                  setTempRiwayat({ ...tempRiwayat, date_taken: e.target.value })
                }
                fixValue={(value) =>
                  setTempRiwayat({ ...tempRiwayat, date_taken: value })
                }
              />

              <Input
                withDropdown
                id="vaksin"
                label="Jenis Vaksin"
                type="text"
                name="vaksin"
                className="mb-8"
                options={vaksin.jenis}
                value={
                  tempRiwayat.vaccine_types === ''
                    ? ''
                    : tempRiwayat?.vaccine_types
                        ?.map((item) => item?.name)
                        ?.join(', ')
                }
                onChange={(e) =>
                  setTempRiwayat({
                    ...tempRiwayat,
                    vaccine_types: e.target.value,
                  })
                }
                fixValue={(value) => {
                  setTempRiwayat({
                    ...tempRiwayat,
                    vaccine_types: [{ id: value.id, name: value.name }],
                  });

                  setIdjenisVaksin(value.id);
                }}
              />

              <Input
                withDropdown
                id="vaksin"
                label="Merek Vaksin"
                type="text"
                name="vaksin"
                className="mb-8"
                options={vaksin.merek}
                value={tempRiwayat.vaccine_merek}
                onChange={(e) =>
                  setTempRiwayat({
                    ...tempRiwayat,
                    vaccine_merek: e.target.value,
                  })
                }
                fixValue={(value) => {
                  setTempRiwayat({
                    ...tempRiwayat,
                    vaccine_id: value.id,
                    vaccine_merek: value.brand_name,
                    vaccine_types: value?.vaccine_types.map((item) => ({
                      id: item.id,
                      name: item.name,
                    })),
                  });
                }}
              />

              <Input
                withOutline
                id="vaksin"
                label="Efek Samping"
                type="text"
                name="vaksin"
                className="mb-8"
                value={tempRiwayat.side_effect}
                onChange={(e) =>
                  setTempRiwayat({
                    ...tempRiwayat,
                    side_effect: e.target.value,
                  })
                }
              />

              <Button
                title="+ Tambah Riwayat Vaksin"
                className="font-inter text-blue1 font-bold text-sm mb-9"
                onClick={() => {
                  setDataRiwayatVaksin([...dataRiwayatVaksin, tempRiwayat]);
                  return setTempRiwayat({
                    idTemp: new Date().getTime(),
                    date_taken: '',
                    vaccine_id: '',
                    vaccine_merek: '',
                    side_effect: '',
                    vaccine_types: '',
                  });
                }}
              />

              <Button
                isDisabled={
                  tempRiwayat.date_taken === '' ||
                  tempRiwayat.vaccine_types === '' ||
                  isDisabledBtnSend
                }
                title="Daftarkan"
                className={`font-inter w-max rounded-3 mx-auto px-6 py-3 text-sm font-bold text-white focus:outline-none  ${
                  tempRiwayat.date_taken === '' ||
                  tempRiwayat.vaccine_types === '' ||
                  isDisabledBtnSend
                    ? 'bg-gray4 '
                    : 'bg-blue1 hover:bg-blue-400 '
                }`}
                onClick={() => _createNewAccount()}
              />
            </div>
          </div>
        )}

        {successCreateFamily && (
          <div className="fixed inset-0 flex justify-center items-center z-30">
            <div
              className="w-auto max-h-full bg-white flex flex-col justify-center py-16 px-8 relative z-30 "
              style={{ width: 420, minHeight: 306 }}
            >
              <div className="absolute top-5 right-7">
                <svg
                  className="cursor-pointer"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setSuccessCreateFamily(false)}
                >
                  <path
                    d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
                    fill="#C8C8C8"
                  />
                </svg>
              </div>

              <h2 className="font-inter font-semibold text-base mb-6 capitalize">
                Pendaftaran Berhasil !
              </h2>

              <p className="font-inter text-sm">
                Pendaftaran Berhasil! Pendaftaran akun baru berhasil dilakukan,
                akun sudah dapat digunakan untuk mendaftarkan vaksinasi.
              </p>

              <div className="flex justify-between mt-8">
                <Button
                  isBtnOutlineSecondary
                  title="Buat jadwal vaksinasi"
                  className="text-blue1"
                  onClick={() =>
                    history.replace(`/dashboard/antrian-klinik/tambah-antrian`)
                  }
                />
                <Button
                  isPrimary
                  title="Lihat Profile"
                  className="rounded-3"
                  onClick={() =>
                    history.replace(`/dashboard/profil/${dataSucces?.id}`)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {showTambahDaftarKeluarga && (
        <Modal
          type="tambah-daftar-keluarga"
          closeModal={() => setShowTambahDaftarKeluarga(false)}
        />
      )}
    </div>
  );
}
