import { IconRefresh, IconSearch } from 'assets';
import { Button, Input, Modal } from 'components/atoms';
import API from 'config/Axios';
import { getAllFamilyMember } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils';

export default function DaftarDokter(props) {
  const [listDoctors, setListDoctors] = useState([]);
  const [showProfilDokter, setShowProfilDokter] = useState({
    show: false,
    data: '',
  });
  const [showHapusDokter, setShowHapusDokter] = useState({
    show: false,
    data: '',
  });
  const [tambahDokterBaru, setTambahDokterBaru] = useState(false);

  const _getAllDoctor = async () => {
    API.get('/staff/doctors')
      .then((res) => {
        setListDoctors(res.data.doctors);
        setShowProfilDokter(false);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    _getAllDoctor();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content flex justify-between border-b-2 pb-10 mb-8 border-gray2 font-inter">
        <h2 className="font-bold text-3xl text-black2">Daftar Dokter</h2>
        <p className="font-medium text-sm">
          Terakhir Diperbaharui : {formatDate(new Date())}
        </p>
      </div>
      {/* END : Header Content */}

      <div className="flex items-center justify-between mb-6">
        <Input
          withIcon
          icon={IconSearch}
          type="text"
          name="search"
          className="px-2 py-1 w-1/3"
          placeholder="Masukkan ID Dokter/Nama Dokter"
        />

        <div className="flex">
          <Button
            type="button-icon"
            icon={IconRefresh}
            className="px-6 rounded bg-white border-2 py-2 border-gray5 ml-6 font-inter font-bold text-blue1"
            // onClick={() => {
            //   props.history.push("/dashboard/homecare/peta-vaksinasi");
            // }}
          />

          <Button
            title="Tambah Dokter Baru"
            className="bg-blue1 px-7 py-3 font-inter font-bold text-sm text-white rounded-3 lg:mt-0  w-full lg:w-max ml-7 hover:bg-blue3  lg:mb-0 whitespace-nowrap"
            onClick={() => setTambahDokterBaru(true)}
          />
        </div>
      </div>

      <div className="flex-1">
        <div className="h-full min-w-full overflow-x-scroll">
          <table className="w-full">
            <thead>
              <tr className="bg-blue4 text-black2">
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  ID Dokter
                </th>
                <th className="w-1/12 py-3 px-8 border border-blue4">
                  Nama Dokter
                </th>
                <th className="w-1/12 py-3 px-2 border border-blue4">Role</th>
                <th className="w-1/12 py-3 px-8 border border-blue4 whitespace-nowrap">
                  Nomor Telpon
                </th>
                <th className="w-1/12 px-1 py-3  border border-blue4 whitespace-nowrap">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              {listDoctors?.map((doctor, i) => (
                <tr className="text-center" key={i}>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {doctor?.id || '-'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {doctor?.fullname || '-'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {doctor?.doctor_category_id === 1
                      ? 'Dokter Umum'
                      : 'Dokter Spesialis'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    {doctor?.user?.phone_number || '-'}
                  </td>
                  <td className="border border-blue4 py-8 px-4 whitespace-nowrap">
                    <Button
                      title="Profil"
                      className="bg-blue1 text-white rounded-3 hover:bg-blue3 font-inter font-medium text-xs focus:bg-blue5 focus:outline-none px-4 py-2 mb-1 mr-2"
                      onClick={() =>
                        setShowProfilDokter({ show: true, data: doctor })
                      }
                    />
                    <Button
                      title="Hapus"
                      className="bg-red1 text-white rounded-3 hover:bg-red-500 font-inter font-medium text-xs focus:bg-red1 focus:outline-none px-4 py-2 mb-1 mr-2"
                      onClick={() =>
                        setShowHapusDokter({ show: true, data: doctor })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white flex pt-4 font-inter justify-between items-center mt-3.5">
        <p className="text-sm text-black2">{`Data 1 hingga 25 dari 1000`}</p>
        <div className="flex flex-col whitespace-nowrap justify-end md:block">
          <Button
            isGreenOutline
            isBtnOutlineSecondary
            title="Prev Page"
            className="mr-2 w-full md:w-max mb-2"
          />
          <Button isGreenOutline isBtnOutlineSecondary title="Next Page" />
        </div>
      </div>

      {showProfilDokter.show && (
        <Modal
          type="profil-dokter"
          data={showProfilDokter.data}
          closeModal={() => setShowProfilDokter(false)}
          onShowEditDoctor={() => setTambahDokterBaru(true)}
          onShowDeleteDoctor={setShowHapusDokter}
        />
      )}

      {tambahDokterBaru && (
        <Modal
          type="tambah-dokter"
          data={showProfilDokter.data}
          closeModal={() => setTambahDokterBaru(false)}
          tes={async () => await _getAllDoctor()}
        />
      )}

      {showHapusDokter.show && (
        <Modal
          isDeleteDoctor
          data={showHapusDokter.data}
          type="hapus-produk-dokter"
          closeModal={() => setShowHapusDokter(false)}
          tes={async () => await _getAllDoctor()}
        />
      )}
    </div>
  );
}
