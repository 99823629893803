import API from 'config/Axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import ReactHTMLParser from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import IMGLogo from '../../../assets/images/img-logo.png';
import { formatNumber } from 'utils/FormatNumber';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

function pxToMm(px) {
  const mmPerInch = 25.4;
  const ppi = 96;
  const mmPerPx = mmPerInch / ppi;
  return (px * mmPerPx).toFixed(2); // round to 2 decimal places
}

export const PrintInvoice = () => {
  const params = useParams();
  const { id } = params;

  const componentRef = useRef();
  const buttonPrintRef = useRef();

  const [receipt, setReceipt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paperHeight, setPaperHeight] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    API.get(`/staff/orders/receipt/${id}`)
      .then((res) => setReceipt(res.data?.receipt))
      .finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (receipt && !isLoading) {
        onPrintDocument();
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [isLoading, receipt]);

  useEffect(() => {
    const paperHeightInMM = Math.floor(
      pxToMm(componentRef?.current?.scrollHeight)
    );
    setPaperHeight(paperHeightInMM);
  }, [componentRef, isLoading]);

  const pageStyle = `
  @page {
    size: 80mm ${paperHeight}mm;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media print {
    .noprint {
      display: none !important;
      height: 0px !important;
    }
  }
`;

  const onPrintDocument = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
      });
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      pdf.addImage(
        imgData,
        'JPEG',
        marginX,
        marginY,
        canvasWidth,
        canvasHeight
      );
      const fileName = `invoice-${receipt?.order?.order_number}`;
      pdf.setProperties({
        title: fileName,
      });

      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl);
    });
  };

  return (
    <>
      <div
        id="divToPrint"
        className="print-page"
        ref={componentRef}
        style={{ width: 'max-content' }}
      >
        <div className="asdasd" style={{ width: '80mm', display: 'table' }}>
          <div style={{ width: '40mm', height: '30mm', margin: '0 auto' }}>
            <img
              src={IMGLogo}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              alt="img-logo.png"
            />
          </div>

          <div style={{ padding: '10px', borderBottom: '1px dashed black' }}>
            <p style={{ margin: 0, textAlign: 'center', fontSize: '14px' }}>
              Jl. A. Mappanyukki No. 119, Kelurahan Mario, Kecamatan Mariso,
              Kota Makassar 0411-8915995
            </p>
          </div>

          <div
            style={{
              padding: '10px',
              borderBottom: '1px dashed black',
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                {receipt?.order?.order_number || '-'}
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                {receipt?.order?.date_plan || '-'}
              </p>
            </div>
          </div>

          <div
            style={{
              padding: '10px',
              borderBottom: '1px dashed black',
              display: 'table',
              width: '100%',
            }}
          >
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                {!!receipt?.items?.length ? (
                  <>
                    {receipt?.items?.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          display: 'table',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'table-cell', width: '60%' }}>
                          <p
                            style={{
                              margin: 0,
                              fontSize: '14px',
                              textAlign: 'left',
                            }}
                          >
                            {item?.name || '-'}
                          </p>
                        </div>
                        <div style={{ display: 'table-cell' }}>
                          <p
                            style={{
                              margin: 0,
                              fontSize: '14px',
                              textAlign: 'right',
                            }}
                          >
                            <NumericFormat
                              prefix="Rp. "
                              thousandSeparator="."
                              decimalSeparator=","
                              displayType="text"
                              value={item?.price}
                            />
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p>No Data</p>
                )}
              </>
            )}
          </div>

          <div
            style={{
              padding: '10px',
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                Total
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p
                style={{
                  margin: 0,
                  fontSize: '14px',
                  textAlign: 'right',
                }}
              >
                <NumericFormat
                  prefix="Rp. "
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  value={receipt?.total}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          @page {
            size: 80mm ${paperHeight}mm;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
          }
          @media print {
            .print-page {
              width: 80mm;
              height: ${paperHeight}mm;
            }
          }
        `}
      </style>
    </>
  );
};
