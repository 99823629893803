import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import IMGLogo from '../../assets/images/img-logo.png';

function pxToMm(px) {
  const mmPerInch = 25.4;
  const ppi = 96;
  const mmPerPx = mmPerInch / ppi;
  return (px * mmPerPx).toFixed(2); // round to 2 decimal places
}

export const PrintInvoiceTes = () => {
  const componentRef = useRef();
  const [paperHeight, setPaperHeight] = useState(0);

  useEffect(() => {
    const paperHeightInMM = Math.floor(
      pxToMm(componentRef?.current?.scrollHeight)
    );
    setPaperHeight(paperHeightInMM);
  }, [componentRef?.current?.scrollHeight]);

  const pageStyle = `
  @page {
    size: 80mm ${paperHeight}mm;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media print {
    .noprint {
      display: none !important;
      height: 0px !important;
    }
  }
`;

  return (
    <div ref={componentRef} style={{ width: 'max-content' }}>
      <div className="asdasd" style={{ width: '80mm', display: 'table' }}>
        <div style={{ width: '40mm', height: '30mm', margin: '0 auto' }}>
          <img
            src={IMGLogo}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            alt="img-logo.png"
          />
        </div>

        <div style={{ padding: '10px', borderBottom: '1px dashed black' }}>
          <p style={{ margin: 0, textAlign: 'center', fontSize: '14px' }}>
            Jl. A. Mappanyukki No. 119, Kelurahan Mario, Kecamatan Mariso, Kota
            Makassar 0411-8915995
          </p>
        </div>

        <div
          style={{
            padding: '10px',
            borderBottom: '1px dashed black',
            display: 'table',
            width: '100%',
          }}
        >
          <div style={{ display: 'table-cell', width: '60%' }}>
            <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
              INV#202303251666
            </p>
          </div>
          <div style={{ display: 'table-cell' }}>
            <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
              2023-03-25
            </p>
          </div>
        </div>

        <div
          style={{
            padding: '10px',
            borderBottom: '1px dashed black',
            display: 'table',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'table',
              width: '100%',
            }}
          >
            <div style={{ display: 'table-cell', width: '60%' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
                PREVERNAR (PCV 13) 1X
              </p>
            </div>
            <div style={{ display: 'table-cell' }}>
              <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
                Rp. 975000
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: '10px',
            borderBottom: '1px dashed black',
            display: 'table',
            width: '100%',
          }}
        >
          <div style={{ display: 'table-cell', width: '60%' }}>
            <p style={{ margin: 0, fontSize: '14px', textAlign: 'left' }}>
              Total
            </p>
          </div>
          <div style={{ display: 'table-cell' }}>
            <p style={{ margin: 0, fontSize: '14px', textAlign: 'right' }}>
              Rp. 975000
            </p>
          </div>
        </div>
      </div>

      <ReactToPrint
        bodyClass="body-ini"
        trigger={() => <button className="noprint">Print this out!</button>}
        content={() => componentRef.current}
        pageStyle={pageStyle}
      />
    </div>
  );
};
