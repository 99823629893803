import { IconClose, IconCloseRed } from 'assets';
import React from 'react';
import cx from 'classnames';
import ButtonNew from '../Button/Button';

export const Modal = ({
  isOpen,
  onClose,
  title,
  text,
  onSubmit,
  isDisabled = false,
}) => {
  return (
    <div
      className={cx(
        'fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center',
        {
          hidden: !isOpen,
        }
      )}
    >
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
        style={{ width: 512, height: 450 }}
      >
        <div
          className="absolute top-5 right-7 cursor-pointer"
          onClick={onClose}
        >
          <img src={IconClose} className="w-5 h-5" />
        </div>

        <div className="border-4 border-red1 rounded-full w-20 h-20 flex items-center justify-center mb-4">
          <img src={IconCloseRed} alt="" className="w-6 h-6" />
        </div>

        <h2 className="font-inter font-bold text-black2 text-center text-2xl mb-12">
          {title}
        </h2>

        <p>{text}</p>

        <div className="flex gap-4 justify-between w-full">
          <ButtonNew isBordered className="flex-1" onClick={onClose}>
            Batal
          </ButtonNew>
          <ButtonNew
            isDisabled={isDisabled}
            isDanger
            className="flex-1"
            onClick={onSubmit}
          >
            Hapus
          </ButtonNew>
        </div>
      </div>
    </div>
  );
};
