import { Button, Input } from 'components/atoms';
import API from 'config/Axios';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

export const AddBlockDate = () => {
  const history = useHistory();
  const { state } = useLocation();

  const { register, setValue, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      date: state?.date || '',
      type: state?.type || '',
      information: state?.information || '',
    },
  });

  const onSubmit = (data) => {
    if (typeof state !== 'object') {
      API.post('/staff/blocked-dates', data)
        .then(() => {
          reset();
          history.push('/dashboard/blocked-dates');
        })
        .catch(() => alert('Gagal Menambahkan Blocked'));
    } else {
      const payloadEditBlockedDate = {
        _method: 'PATCH',
        ...data,
      };
      API.post(`/staff/blocked-dates/${state?.id}`, payloadEditBlockedDate)
        .then(() => {
          reset();
          history.push('/dashboard/blocked-dates');
        })
        .catch(() => alert('Gagal Menambahkan Blocked'));
    }
  };

  return (
    <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content border-b-2 pb-5 border-gray2 text-black">
        <h2 className="font-bold text-3xl mb-1">Tambah Blocked Date</h2>
      </div>
      {/* END : Header Content */}

      <div className="mt-5 w-full md:w-1/2">
        <div className="flex items-center mb-4">
          <Input
            withDate
            withDateNow
            disableDay
            className="w-11/12"
            label="Tanggal"
            type="text"
            name="date"
            fixValue={(value) => {
              return setValue('date', value);
            }}
            value={watch('date')}
            onChange={(e) => {
              return setValue('date', e.target.value);
            }}
            ref={register({
              required: true,
            })}
          />
        </div>
        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Tipe"
            name="type"
            className="w-11/12"
            ref={register({
              required: true,
            })}
          />
        </div>

        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Deskripsi"
            name="information"
            className="w-11/12"
            ref={register({
              required: true,
            })}
          />
        </div>

        <div className="flex justify-between w-11/12 ">
          <Button
            isBtnOutlineSecondary
            isPrimaryOutline
            title="Batal"
            onClick={() => history.goBack()}
            className="w-44 mr-6"
          />
          <Button
            isPrimary
            title={typeof state === 'object' ? 'Edit' : 'Simpan'}
            onClick={handleSubmit(onSubmit)}
            className="w-44"
          />
        </div>
      </div>
    </div>
  );
};
