import API from 'config/Axios';
import React, { useEffect, useRef, useState } from 'react';
import { formatDateAsc } from 'utils';
import { Input } from '..';
import Button from '../Button';
import cx from 'classnames';

export default function TambahDokter({ closeModal, data, tes }) {
  const jenisKelamin = [
    {
      name: 'Laki - Laki',
      value: 'male',
    },
    {
      name: 'Perempuan',
      value: 'female',
    },
  ];

  const [roleDokter, setRoleDokter] = useState('');
  const [tempLinkImg, setTempLinkImg] = useState('');

  const [dataTambahDokter, setDataTambahDokter] = useState({
    fullname: data?.fullname || '',
    email: data?.user?.email || '',
    password: '123123123',
    phone_number: data?.user?.phone_number || '',
    doctor_category_id: {
      id: data?.doctor_category_id || '',
      title: data?.doctor_category?.name || '',
    },
    gender: {
      id:
        jenisKelamin?.filter(
          (item) => item?.value == data?.gender?.toLowerCase()
        )?.[0]?.value || '',
      title:
        jenisKelamin?.filter(
          (item) => item?.value == data?.gender?.toLowerCase()
        )?.[0]?.name || '',
    },
    birthdate: data?.birthdate || '',
    birthplace: data?.birthplace || '',
    address: data?.address || '',
    room_id: data?.room_id || '2',
    image: data?.image_url || '',
  });

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleUploadGambar = (e) => {
    let img = e.target.files[0];
    let tempImg = URL.createObjectURL(img);
    setTempLinkImg(tempImg);
    setDataTambahDokter({ ...dataTambahDokter, image: img });
  };

  const _getRoleDokter = async () => {
    await API.get('/staff/doctors/categories')
      .then((res) => {
        return setRoleDokter(res.data.doctorCategories);
      })
      .catch((err) => {
        return err;
      });
  };

  const handleClickSimpan = async () => {
    const sendData = new FormData();
    sendData.append('fullname', dataTambahDokter.fullname);
    sendData.append(
      'doctor_category_id',
      dataTambahDokter.doctor_category_id.id
    );
    sendData.append('gender', dataTambahDokter.gender.id);
    sendData.append('birthdate', dataTambahDokter.birthdate);
    sendData.append('birthplace', dataTambahDokter.birthplace);
    sendData.append('address', dataTambahDokter.address);
    sendData.append('room_id', dataTambahDokter.room_id);

    if (data?.id) {
      sendData.append('_method', 'PATCH');
    } else {
      sendData.append('email', dataTambahDokter.email);
      sendData.append('password', dataTambahDokter.password);
      sendData.append('phone_number', dataTambahDokter.phone_number);
    }

    await API.post(
      `/staff/doctors${data?.id ? `/${data?.id}` : ''}`,
      sendData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    )
      .then((res) => {
        closeModal();
        return tes();
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    _getRoleDokter();
  }, []);

  if (roleDokter === '') {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
        <div
          className="w-auto max-h-full bg-white flex flex-col py-16 px-12 relative z-30 overflow-y-scroll scrollbar-none"
          style={{ width: 420, minHeight: 483 }}
        >
          <p>Loading....</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white flex flex-col py-16 px-12 relative z-30 overflow-y-scroll scrollbar-none"
        style={{ width: 420, minHeight: 483 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>

        <div className="flex justify-between items-center mb-5">
          <h2 className="font-inter font-bold text-lg text-black2">
            Tambah Dokter
          </h2>
        </div>

        <p className="font-inter font-bold text-sm text-black mb-4">
          Foto Profil
        </p>

        <div className="flex items-center">
          {dataTambahDokter.image === '' ? (
            <div className="w-20 h-20 p-2.5 rounded-full bg-blue2 flex justify-center items-center">
              <svg
                width="68"
                height="68"
                viewBox="0 0 68 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M56.5 56.6875V51.0625C56.5 48.0788 55.3147 45.2173 53.2049 43.1076C51.0952 40.9978 48.2337 39.8125 45.25 39.8125H22.75C19.7663 39.8125 16.9048 40.9978 14.795 43.1076C12.6853 45.2173 11.5 48.0788 11.5 51.0625V56.6875"
                  stroke="#0A71D1"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLineJoin="round"
                />
                <path
                  d="M32.5938 31.375C38.0303 31.375 42.4375 26.9678 42.4375 21.5312C42.4375 16.0947 38.0303 11.6875 32.5938 11.6875C27.1572 11.6875 22.75 16.0947 22.75 21.5312C22.75 26.9678 27.1572 31.375 32.5938 31.375Z"
                  stroke="#0A71D1"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLineJoin="round"
                />
                <path
                  d="M9.54199 57.3906H58.5764"
                  stroke="#0A71D1"
                  strokeWidth="5"
                />
              </svg>
            </div>
          ) : (
            <div className="w-20 h-20 overflow-hidden flex justify-center items-center rounded-full">
              <img
                src={tempLinkImg}
                alt="new-dokter"
                className="bg-cover bg-center"
              />
            </div>
          )}

          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={(e) => handleUploadGambar(e)}
            accept="image/*"
          />

          <Button
            title="Tambah Foto Profil"
            className="font-bold font-inter text-sm text-blue1 ml-8"
            onClick={() => onButtonClick()}
          />
        </div>

        <p className="font-inter font-bold text-sm text-black2 my-6">
          Data Diri
        </p>

        <div className="">
          <Input
            withOutline
            name="nama-dokter"
            label="Nama Dokter"
            className="mb-6"
            value={dataTambahDokter.fullname}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                fullname: e.target.value,
              })
            }
          />

          <Input
            withDropdown
            options={roleDokter}
            name="role"
            label="Role"
            className="mb-6"
            value={dataTambahDokter.doctor_category_id.title}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                doctor_category_id: e.target.value,
              })
            }
            fixValue={(value) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                doctor_category_id: { id: value.id, title: value.name },
              })
            }
          />

          <Input
            withDropdown
            options={jenisKelamin}
            name="jenis-kelamin"
            label="Jenis Kelamin"
            className="mb-6"
            value={dataTambahDokter.gender.title}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                gender: e.target.value,
              })
            }
            fixValue={(value) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                gender: { id: value.value, title: value.name },
              })
            }
          />

          <Input
            withOutline
            name="tempat-lahir"
            label="Tempat Lahir"
            className="mb-6"
            value={dataTambahDokter.birthplace}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                birthplace: e.target.value,
              })
            }
          />

          <Input
            withDate
            name="tgl-lahir"
            label="Tanggal Lahir"
            className="mb-6"
            value={
              dataTambahDokter.birthdate === ''
                ? ''
                : formatDateAsc(dataTambahDokter.birthdate)
            }
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                birthdate: e.target.value,
              })
            }
            fixValue={(value) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                birthdate: value,
              })
            }
          />

          <Input
            withOutline
            name="alamat"
            label="Alamat"
            className="mb-6"
            value={dataTambahDokter.address}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                address: e.target.value,
              })
            }
          />

          <Input
            withOutline
            name="nomor-telpon"
            label="No Telpon"
            className={cx('mb-6', {
              hidden: data?.id,
            })}
            value={dataTambahDokter.phone_number}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                phone_number: e.target.value,
              })
            }
          />

          <Input
            withOutline
            name="email"
            label="Email"
            className={cx('', {
              hidden: data?.id,
            })}
            value={dataTambahDokter.email}
            onChange={(e) =>
              setDataTambahDokter({
                ...dataTambahDokter,
                email: e.target.value,
              })
            }
          />
        </div>
        <Button
          isPrimary
          title="Simpan"
          className="rounded-3 mx-auto px-14 mt-10"
          onClick={() => handleClickSimpan()}
        />
      </div>
    </div>
  );
}
