import { IconClose, IconLoading, IconMap, IconMarkerMaps } from 'assets';
import {
  checkTicket,
  getAllPaymentMethod,
  getFamilyMember,
  getKategoriDokter,
  getLokasiPemberianVaksin,
  getNamaPasien,
  getPungukuranPasien,
  getRegistrationCount,
  getShiftId,
  getStatusAntrian,
  getStatusPembayaran,
  jadwalVaksinasiSelanjutnya,
  tambahAntrian,
} from 'config/Redux/action';
import { useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { formatNumber, getPaymentChannel } from 'utils';
import { Button, Input, Modal } from '../../atoms';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import API from 'config/Axios';
import ButtonNew from 'components/atoms/Button/Button';

const libraries = ['places'];

export default function JadwalVaksin(props) {
  const [lokasiVaksin, setLokasiVaksin] = useState([]);
  const [fixLokasi, setFixLokasi] = useState({
    id: '',
    title: '',
  });

  const [kategoriDokter, setKategoriDokter] = useState([]);
  const [fixKategoriDokter, setFixKategoriDokter] = useState({
    id: '',
    title: '',
  });
  const [vaksinator, SetVaksinator] = useState({
    id: '',
    title: '',
  });

  const [statusAntrian, setStatusAntrian] = useState([]);
  const [fixStatusAntrian, setFixStatusAntrian] = useState({
    id: '',
    title: '',
  });

  const [shiftId, setShiftId] = useState({
    id: '',
    title: '',
  });
  const [listShiftId, setListShiftId] = useState([]);

  const [statusPembayaran, setStatusPembayaran] = useState([]);
  const [fixStatusPembayaran, setFixStatusPembayaran] = useState({
    id: '',
    title: '',
  });
  const [fixPaymentChannel, setFixPaymentChannel] = useState({
    id: '',
    title: '',
  });

  const [inputNama, setInputNama] = useState([]);
  const [tglVaksin, setTglVaksin] = useState('');
  const [noHp, setNoHp] = useState('');

  const [registrationCount, setRegistrationCount] = useState({});

  const [showModal, setShowModal] = useState({
    show: false,
    idInput: '',
    data: '',
  });

  const [fixVasin, setFixVasin] = useState('');
  const [detailPasein, setDetailPasein] = useState('');

  const [addressId, setAddressId] = useState(0);
  const [IdMetodePembayaran, setIdMetodePembayaran] = useState({
    id: '',
    title: '',
  });
  const [isDisabledCancel, setIsDisabledCancel] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleClick = async () => {
    let tes = [];
    let sendData = {};

    penerimaVaksin?.map((item, i) => {
      item.vaccines?.map((data) => {
        tes.push({
          vaccine_id: data.vaccine_id,
          vaccine_types: data.vaccine_types,
        });
      });

      item.vaccines = tes;

      sendData.date = tglVaksin;
      sendData.family_member_id = detailPasein.id;
      sendData.registration_origin_id = 3;
      sendData.service_location_id = fixLokasi;
      sendData.doctor_id = vaksinator;
      sendData.phone_number = noHp;
      sendData.queue_status_id = fixStatusAntrian;
      sendData.payment_status_id = fixStatusPembayaran;
      sendData.vaccines = tes;
      sendData.shift_id = 1;
    });

    if (
      !sendData.date ||
      !sendData.shift_id ||
      !sendData.registration_origin_id ||
      !sendData.service_location_id ||
      !sendData.doctor_id ||
      !sendData.phone_number ||
      !sendData.queue_status_id ||
      !sendData.payment_status_id ||
      !sendData.vaccines
    ) {
      return alert('Masih ada yang kosong');
    }

    // setShowLoading(true);
    // const send = await dispatch(
    //   jadwalVaksinasiSelanjutnya("/staff/vaccinations/schedule", sendData)
    // );
    // setResponse(send);
  };

  const onSubmit = async (data) => {
    let vaksinId = [];
    let vaksinType = [];
    let sendData = {
      ...(isShowPaymentChannel
        ? { payment_channel_id: fixPaymentChannel.id }
        : {}),
    };

    penerimaVaksin?.map((item, i) => {
      item.vaccines?.map((data) => {
        vaksinId.push(data.vaccine_id);
        data.vaccine_types?.map((item) =>
          vaksinType.push(item.vaccine_type_id)
        );
      });

      sendData.date_plan = tglVaksin;
      sendData.family_member_id = detailPasein.id;
      sendData.registration_origin_id = 3;
      sendData.service_location_id = fixLokasi.id;
      sendData.doctor_id = vaksinator.id;
      sendData.phone_number = noHp;
      // sendData.queue_status_id = fixStatusAntrian;
      sendData.payment_method_id = IdMetodePembayaran.id;
      sendData.payment_status_id = fixStatusPembayaran.id;
      sendData.vaccine_recipients = [
        {
          family_member_id: detailPasein.id,
          vaccine_ids: vaksinId,
        },
      ];
      sendData.shift_id = shiftId.id;
      sendData.contact_person_name = detailPasein.fullname;
    });

    if (
      !sendData.date_plan ||
      !sendData.shift_id ||
      !sendData.registration_origin_id ||
      !sendData.service_location_id ||
      !sendData.doctor_id ||
      !sendData.phone_number ||
      // !sendData.queue_status_id ||
      !sendData.payment_method_id ||
      !sendData.payment_status_id ||
      !sendData.vaccine_recipients
    ) {
      return alert('Masih ada yang kosong');
    }

    setShowLoading(true);
    const send = await dispatch(tambahAntrian('/staff/orders', sendData));
    setResponse(send);
  };

  const getAntrianCount = async (tanggal) => {
    const _getRegistationCount = await dispatch(
      getRegistrationCount('/staff/queues/registration-count?', tanggal, 1)
    );

    setRegistrationCount(_getRegistationCount);
  };

  const INITIAL_VALUE_PATIENT_VACCINE = {
    id: new Date().getTime(),
    family_member_id: detailPasein.id,
    vaccines: [
      {
        id: new Date().getTime(),
        vaccine_id: '',
        merek: '',
        vaccine_types: [],
      },
    ],
  };
  const [penerimaVaksin, setPenerimaVaksin] = useState([
    INITIAL_VALUE_PATIENT_VACCINE,
  ]);
  const [showLoading, setShowLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [fixHarga, setFixHarga] = useState([]);
  const [fixMetodePembayaran, setFixMetodePembayaran] = useState([]);
  const [isShowPaymentChannel, setIsShowPaymentChannel] = useState(false);

  const allPaymentChannel = getPaymentChannel();

  const isLoading = useSelector((state) => state.isLoading);

  // START : Config Maps
  const [fixAlamat, setFixAlamat] = useState('');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [autoComplete, setAutoComplete] = useState(null);
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [showMaps, setShowMaps] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB_73hYRCjyR5yeJXgQHcYjun6JGYGpM8E',
    libraries,
    language: 'id',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoadAutoComplete = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChangedAutoComplete = () => {
    if (autoComplete !== null) {
      const tes = autoComplete.getPlace();

      setFixAlamat(tes.formatted_address);
      setPosition({
        ...position,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });

      setCenter({
        ...center,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const lokasiBaru = (latlng) => {
    var geocoder = (geocoder = new window.google.maps.Geocoder());
    return geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          setFixAlamat(results[1].formatted_address);
        }
      }
    });
  };

  const handleDrag = (drag) => {
    setPosition({
      ...position,
      lat: drag.latLng.lat(),
      lng: drag.latLng.lng(),
    });
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        ...center,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  // END: Config Maps

  useEffect(() => {
    const _getData = async () => {
      const tempatVaksinasi = await dispatch(
        getLokasiPemberianVaksin('/staff/service-locations')
      );
      setLokasiVaksin(tempatVaksinasi);

      const kategoriDokter = await dispatch(
        getKategoriDokter('/staff/doctors/categories')
      );

      setKategoriDokter(kategoriDokter);

      const listStatusAntrian = await dispatch(
        getStatusAntrian('/staff/queue-statuses', fixLokasi)
      );
      setStatusAntrian(listStatusAntrian.data.queueStatuses);

      const listStatusPembayaran = await dispatch(
        getStatusPembayaran('/staff/payment-statuses')
      );
      setStatusPembayaran(listStatusPembayaran);

      const detail = await dispatch(
        getFamilyMember(`/staff/family-members/${id}`)
      );
      setDetailPasein(detail);

      const shift = await dispatch(getShiftId('/staff/shifts'));
      setListShiftId(shift);

      const paymentMethod = await dispatch(
        getAllPaymentMethod('/staff/payments/methods')
      );
      setFixMetodePembayaran(paymentMethod);
    };
    _getData();
  }, [penerimaVaksin, fixVasin, registrationCount, fixLokasi]);

  useEffect(() => {
    const tempIsShowPaymentChannel =
      fixStatusPembayaran?.id ===
      statusPembayaran.filter((item) => item.name === 'Sudah Bayar')?.[0]?.id;
    setIsShowPaymentChannel(tempIsShowPaymentChannel);
  }, [fixStatusPembayaran, statusPembayaran]);

  const onResetForm = () => {
    setIsDisabledCancel(true);
    setNoHp('');
    setPenerimaVaksin([INITIAL_VALUE_PATIENT_VACCINE]);
    setFixLokasi({
      id: '',
      title: '',
    });
    setAddressId(0);
    setFixAlamat('');
    setTglVaksin('');
    setFixKategoriDokter({
      id: '',
      title: '',
    });
    SetVaksinator({
      id: '',
      title: '',
    });
    setShiftId({
      id: '',
      title: '',
    });
    setNoHp('');
    setIdMetodePembayaran({
      id: '',
      title: '',
    });
    setFixStatusPembayaran({
      id: '',
      title: '',
    });
    setFixStatusAntrian({
      id: '',
      title: '',
    });
    reset();

    setTimeout(() => {
      setIsDisabledCancel(false);
    }, 2000);
  };

  if (detailPasein === '' || fixMetodePembayaran === '') {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen overflow-hidden flex flex-col  pt-11 pb-7 px-9 bg-white">
      <div className="max-h-screen flex-1 overflow-y-scroll scrollbar-none pb-9 px-1">
        {/* START : Header Content Tambah Antrian */}
        <div className="content border-b-2 pb-8 border-gray2 font-inter">
          <h2 className="font-bold text-3xl text-black">Detail Vaksinasi</h2>
          <p>Tn. {detailPasein?.fullname || '-'}</p>
        </div>
        {/* END : Header Content Tambah Antrian  */}

        <div className="md:block lg:flex mt-4 min-w-full pb-4">
          <div className="w-full lg:w-6/12">
            <div className="font-inter pb-5 mb-6">
              <h2 className="font-bold text-lg text-black mb-4">
                Vaksinasi Berikutnya
              </h2>

              {penerimaVaksin?.map((penerima, i) => (
                <div key={i}>
                  {penerima.vaccines?.map((jenis, index) => (
                    <div
                      className="mb-5 border-b-2 py-2 border-gray2"
                      key={index}
                    >
                      <div className="flex items-stretch justify-center mb-5">
                        <Input
                          id={jenis.id}
                          withOutline
                          label="Jenis Vaksin"
                          type="text"
                          name="jenis-vaksin"
                          className="flex-1"
                          value={jenis.merek}
                          readOnly
                          onClick={() =>
                            setShowModal({
                              show: true,
                              idInput: penerima.vaccines[index]?.id,
                              data: penerima,
                            })
                          }
                          ref={register({
                            required: true,
                          })}
                          placeholder="Pilih Jenis Vaksin"
                        />

                        {penerima.vaccines?.length > 1 && (
                          <Button
                            title="X"
                            className="text-2xl px-4 border bg-red1 border-red1 rounded-3  font-inter  text-white font-extralight ml-4"
                            onClick={async () => {
                              const newDataPenerima = [...penerimaVaksin];

                              const deleteIds = penerima.vaccines[index].id;

                              let fixData = newDataPenerima?.map((item, o) => {
                                const tes = item.vaccines.filter(
                                  (data) => data.id !== deleteIds
                                );

                                item.vaccines = tes;
                                return item;
                              });

                              setPenerimaVaksin(fixData);
                            }}
                          />
                        )}
                      </div>

                      {showModal.show &&
                        showModal.idInput === penerima.vaccines[index]?.id && (
                          <Modal
                            isNextVaksin
                            modalId={showModal.idInput}
                            data={showModal.data}
                            type="jenis-vaksin"
                            closeModal={() => setShowModal(!showModal)}
                            value={(data, modalId) => {
                              data?.vaccines?.map((item, i) => {
                                setFixHarga([...fixHarga, item.price]);
                              });
                            }}
                          />
                        )}
                    </div>
                  ))}

                  <div className="w-full flex justify-end">
                    <Button
                      title="+ Jenis Vaksin"
                      className="font-inter font-medium text-blue1 text-sm rounded-3 text-right"
                      onClick={() => {
                        const newArr = [...penerimaVaksin];
                        const newData = {
                          id: new Date().getTime(),
                          vaccine_id: '',
                          merek: '',
                          vaccine_types: [],
                        };

                        newArr[i].vaccines.push(newData);
                        setPenerimaVaksin(newArr);
                      }}
                    />
                  </div>

                  <div className="my-5 border-b-2 border-gray2"></div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full lg:w-1/12"></div>

          <div className="w-full lg:w-6/12 md:mt-10 lg:my-0">
            <h2 className="font-inter font-bold text-lg text-black mb-4">
              Jadwal Vaksin
            </h2>

            <Input
              withDropdown
              label="Tempat Pemberian Vaksin"
              type="text"
              name="lokasi-vaksin"
              className="mb-5"
              options={lokasiVaksin}
              value={fixLokasi.title}
              fixValue={(value) =>
                setFixLokasi({ id: value.id, title: value.name })
              }
              ref={register({
                required: true,
              })}
              placeholder="Pilih Tempat Vaksinasi"
            />

            {fixLokasi.id === 2 && (
              <>
                {showMaps && (
                  <div className="absolute inset-0 bg-black bg-opacity-60 z-20 flex flex-col justify-center items-center">
                    <div className="bg-white p-4 rounded-10">
                      <div className="flex justify-end mb-5">
                        <Button
                          type="button-icon"
                          icon={IconClose}
                          alt="close-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowMaps(false);
                          }}
                        />
                      </div>
                      <GoogleMap
                        id="searchbox-example"
                        // onLoad={onLoad}
                        // onUnmount={onUnmount}
                        mapContainerStyle={{
                          width: 400,
                          height: 400,
                          display: 'block',
                        }}
                        zoom={15}
                        center={center}
                        options={{
                          disableDefaultUI: true,
                          zoomControl: true,
                        }}
                      >
                        <Marker
                          position={position}
                          visible={
                            position.lat === 0 || position.lng === 0
                              ? false
                              : true
                          }
                          draggable
                          // onDrag={(drag) => handleDrag(drag)}
                          onDragEnd={(drag) => lokasiBaru(position)}
                          icon={IconMarkerMaps}
                        />
                      </GoogleMap>
                      <div className="flex justify-center mt-5">
                        <Button
                          title="Simpan"
                          isPrimary
                          className="rounded-3"
                          onClick={async (e) => {
                            e.preventDefault();

                            const data = {};
                            data.family_id = detailPasein.family_id;
                            data.full_address = fixAlamat;
                            data.latitude = position.lat;
                            data.longitude = position.lng;

                            await API.post('/staff/addresses', data)
                              .then((res) => {
                                setAddressId(res.data.address.id);
                                setShowMaps(false);
                              })
                              .catch((err) => err);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Autocomplete
                  onLoad={(a) => onLoadAutoComplete(a)}
                  onPlaceChanged={(a) => onPlaceChangedAutoComplete(a)}
                >
                  <div
                    className={`flex items-stretch justify-center mb-5 mt-5`}
                  >
                    <Input
                      withOutline
                      label="Alamat"
                      name="alamat"
                      type="text"
                      placeholder="Alamat"
                      className={`flex-1 mr-5 `}
                      value={fixAlamat}
                      onChange={(e) => setFixAlamat(e.target.value)}
                    />

                    <Button
                      type="button-icon"
                      icon={IconMap}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowMaps(true);
                      }}
                      className="px-4 border bg-blue1 border-blue1 rounded-3 font-inter font-bold text-blue1"
                    />
                  </div>
                </Autocomplete>

                <Input
                  withOutline
                  label="Keterangan Tambahan Alamat"
                  type="text"
                  name="keteranga-alamat"
                  className="mb-5"
                />
              </>
            )}

            <Input
              withDate
              withDateNow
              disableDay
              serviceLocationId={fixLokasi}
              label="Tanggal Vaksin"
              type="text"
              name="tgl-vaksinasi"
              className="mb-5"
              fixValue={(e) => {
                getAntrianCount(e);
                setTglVaksin(e);
              }}
              value={tglVaksin}
              onChange={(e) => {
                // getAntrianCount(e.target.value);
                return setTglVaksin(e.target.value);
              }}
              ref={register({
                required: true,
              })}
            />

            {tglVaksin !== '' && (
              <p className="mb-7 flex justify-between font-inter text-sm">
                <span className="font-bold text-gray1 ">
                  {registrationCount.registration_count} Orang Terdaftar, dari
                  maksimal {registrationCount.registration_limit} orang
                </span>
                <Button
                  title="Tambah kuota antrian"
                  className="  text-blue1 rounded-3"
                  onClick={() => {}}
                />
              </p>
            )}

            <Input
              withDropdown
              label="Pilih Dokter"
              type="text"
              name="pilih-dokter"
              className="mb-5"
              options={kategoriDokter}
              value={fixKategoriDokter.title}
              fixValue={(value) => {
                setFixHarga([...fixHarga, value.service_price]);
                setFixKategoriDokter({ id: value.id, title: value.name });
              }}
              ref={register({
                required: true,
              })}
              placeholder="Pilih Dokter"
            />

            <Input
              withDropdown
              label="Vaksintor"
              type="text"
              name="vaksinator"
              className="mb-5"
              options={
                fixKategoriDokter.id === 1
                  ? kategoriDokter[0]?.doctors
                  : kategoriDokter[1]?.doctors
              }
              value={vaksinator.title}
              fixValue={(value) =>
                SetVaksinator({ id: value.id, title: value.fullname })
              }
              ref={register({
                required: true,
              })}
              placeholder="Pilih Vaksinator"
            />

            <Input
              withDropdown
              label="Shift"
              type="text"
              name="shift"
              className="mb-5"
              options={listShiftId}
              value={shiftId}
              serviceLocationId={fixLokasi.id}
              placeholder=""
              value={shiftId.title}
              fixValue={(value) =>
                setShiftId({ id: value.id, title: value.name })
              }
              ref={register({
                required: true,
              })}
            />

            <Input
              withOutline
              label="Nomor Yang Bisa Di Hubungi"
              type="text"
              name="nomor-hp"
              className="mb-5"
              value={noHp}
              placeholder="ex: 0851231223123"
              onChange={(e) => setNoHp(e.target.value)}
              ref={register({
                required: true,
              })}
            />

            <Input
              withDropdown
              label="Metode Pembayaran"
              type="text"
              name="metode-pembayaran"
              className="mb-5"
              options={fixMetodePembayaran}
              value={IdMetodePembayaran.title}
              fixValue={(value) =>
                setIdMetodePembayaran({ id: value.id, title: value.name })
              }
              ref={register({
                required: true,
              })}
              placeholder="Pilih Status Pembayaran"
            />

            <Input
              withDropdown
              label="Status Pembayaran"
              type="text"
              name="status-pembayaran"
              className="mb-5"
              options={statusPembayaran}
              value={fixStatusPembayaran.title}
              fixValue={(value) =>
                setFixStatusPembayaran({ id: value.id, title: value.name })
              }
              ref={register({
                required: true,
              })}
              placeholder="Pilih Status Pembayaran"
            />

            {isShowPaymentChannel && (
              <Input
                withDropdown
                label="Payment Channel"
                type="text"
                name="payement-channel"
                className="mb-5"
                options={allPaymentChannel}
                value={fixPaymentChannel.title}
                fixValue={(value) =>
                  setFixPaymentChannel({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Payment Channe;"
              />
            )}

            <Input
              withDropdown
              label="Status Antrian"
              type="text"
              name="status-antrian"
              className="mb-5"
              serviceLocationId={fixLokasi}
              options={statusAntrian}
              value={fixStatusAntrian.title}
              fixValue={(value) =>
                setFixStatusAntrian({ id: value.id, title: value.name })
              }
              ref={register({
                required: true,
              })}
              placeholder="Pilih Status Antrian"
            />
          </div>
        </div>
      </div>

      <div className="md:block lg:flex lg:justify-between lg:items-center -mx-9 px-10 pt-7 border-t-8 border-blue2">
        <div className="font-inter">
          <h2 className="font-bold text-lg text-black">
            Total Biaya :
            <span className="font-bold text-xl mr-7">{`Rp. ${formatNumber(
              fixHarga?.reduce(
                (acc, curr) => (acc ? acc : 0) + (curr ? curr : 0),
                0
              )
            )}`}</span>
          </h2>
        </div>

        <div className="md:mt-4 md:flex md:justify-between md:-mx-4 lg:mx-0 lg:mt-0">
          <ButtonNew
            isBordered
            isDisabled={isDisabledCancel}
            className="w-full md:w-1/2 md:mx-4 lg:mx-0 lg:mr-5 lg:w-44 rounded-3 cursor-pointer my-4 md:my-0"
            onClick={onResetForm}
          >
            Reset
          </ButtonNew>
          {/* <Button
            isPrimary
            title="Buat Antrian"
            className=" w-full md:w-1/2 md:mx-4 lg:mx-0 lg:w-44 rounded-3"
            onClick={handleClick}
          /> */}

          <Button
            type="submit"
            isPrimary
            title="Buat Antrian"
            className=" w-full md:w-1/2 md:mx-4 lg:mx-0 lg:w-44 rounded-3"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      {showLoading && (
        <Modal
          isLoading={isLoading}
          message={`${
            response.status === 200
              ? 'Data Jadwal Vaksinasi Selanjutnya Berhasil Disimpan'
              : 'Data Jadwal Vaksinasi Selanjutnya Gagal Disimpan'
          }`}
          closeModal={() => {
            setShowLoading(false);
            props.history.push(`/dashboard/profil/${id}`);
          }}
        />
      )}
    </div>
  );
}
