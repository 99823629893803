import { IconLoading } from 'assets';
import { Button, Input, Modal } from 'components/atoms';
import {
  doPungukuranPasien,
  getPungukuranPasien,
  updatePungukuranPasien,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { formatDateShort, useForm } from 'utils';

export default function Pengukuran(props) {
  const history = useHistory();
  const [dataPasien, setDataPasien] = useState('');
  const [showModal, setShowModal] = useState(false);
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    body_weight: '',
    body_height: '',
    head_circumference: '',
    body_temperature: '',
    queue_id: props.match.params.id,
    date_taken: formatDateShort(new Date()),
    nurse_id: localStorage.getItem('perawat_id'),
  });

  const [responseStatus, setResponseStatus] = useState(0);

  const _getDataPasien = async () => {
    const data = await dispatch(
      getPungukuranPasien(`/staff/queues/${props.match.params.id}`)
    );
    setDataPasien(data);

    setForm({
      ...form,
      body_height: data?.measurement?.body_height,
      body_weight: data?.measurement?.body_weight,
      head_circumference: data?.measurement?.head_circumference,
      body_temperature: data?.measurement?.body_temperature,
    });
  };

  useEffect(() => {
    _getDataPasien();
  }, []);

  if (!dataPasien) {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll">
      {/* START : Header Content */}
      <div className="content border-b-2 pb-5 border-gray2 text-black">
        <h2 className="font-bold text-3xl mb-1">Pengukuran</h2>
        <p className="text-sm">a.n {dataPasien.family_member.fullname}</p>
      </div>
      {/* END : Header Content */}

      <div className="mt-5 w-full md:w-1/2">
        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Berat Badan"
            type="number"
            name="berat-badan"
            className="w-11/12"
            value={form.body_weight}
            onChange={(e) =>
              setForm({
                ...form,
                body_weight: parseFloat(e.target.value),
              })
            }
          />
          <p className="p-4 w-1/12">Kg</p>
        </div>
        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Tinggi Badan"
            type="number"
            name="tinggi-badan"
            className="w-11/12"
            value={form.body_height}
            onChange={(e) =>
              setForm({
                ...form,
                body_height: parseFloat(e.target.value),
              })
            }
          />
          <p className="p-4 w-1/12">Cm</p>
        </div>

        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Lingkar Kepala"
            type="number"
            name="lingkar-kepala"
            className="w-11/12"
            value={form.head_circumference}
            onChange={(e) =>
              setForm({
                ...form,
                head_circumference: parseFloat(e.target.value),
              })
            }
          />
          <p className="p-4 w-1/12">Cm</p>
        </div>
        <div className="flex items-center mb-4">
          <Input
            withOutline
            label="Suhu Tubuh"
            type="number"
            name="Suhu Tubuh"
            className="w-11/12"
            value={form.body_temperature}
            onChange={(e) =>
              setForm({
                ...form,
                body_temperature: parseFloat(e.target.value),
              })
            }
          />
          <p className="p-4 w-1/12">
            <sup>o</sup>C
          </p>
        </div>

        <div className="flex justify-between w-11/12 ">
          <Button
            isBtnOutlineSecondary
            isPrimaryOutline
            title="Batal"
            onClick={() => props.history.goBack()}
            className="w-44 mr-6"
          />
          <Button
            isPrimary
            isDisabled={
              form.body_weight === '' ||
              form.body_height === '' ||
              form.head_circumference === '' ||
              form.body_temperature === ''
                ? true
                : false
            }
            title="Simpan"
            onClick={async () => {
              setShowModal(true);

              if (!dataPasien.measurement) {
                const response = await dispatch(
                  doPungukuranPasien(
                    '/staff/measurements',
                    form,
                    dataPasien.family_member_id
                  )
                );
                setResponseStatus(response);
              } else {
                const response = await dispatch(
                  updatePungukuranPasien(
                    `/staff/measurements/${dataPasien.measurement.id}`,
                    form,
                    dataPasien.family_member_id
                  )
                );
                setResponseStatus(response);
              }
              setForm('reset');
            }}
            className="w-44"
          />
        </div>
      </div>

      {showModal && (
        <Modal
          isLoading={isLoading}
          closeModal={() => {
            setShowModal(!showModal);
            if (responseStatus >= 300) return null;
            else history.goBack();
          }}
          message={
            responseStatus >= 300
              ? 'Pengukuran Gagal Tersimpan'
              : 'Hasil Pengukuran Tersimpan'
          }
        />
      )}
    </div>
  );
}
