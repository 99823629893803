import { IconLoading, IconRightBlue } from 'assets';
import { getMerekVaksin } from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '..';

export default function RiwayatJenisVaksin({ closeModal, value }) {
  const [showListJenisVaksin, setShowListJenisVaksin] = useState(false);

  const [listVaksin, setListVaksin] = useState('');
  const [selectedVaksin, setSelectedVaksin] = useState('');
  const dispatch = useDispatch();

  const handleClickList = () => {
    closeModal(true);
    return value(selectedVaksin);
  };

  useEffect(() => {
    const _getMerekVaksin = async (id) => {
      const merekVaksin = await dispatch(getMerekVaksin(`/staff/vaccines`));
      setListVaksin(merekVaksin.vaccines);
    };
    _getMerekVaksin();
  }, []);

  if (!listVaksin) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-20">
        <div
          className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative"
          style={{ width: 512, height: 450 }}
        >
          <div className="">
            <div className="flex justify-center mb-16 animate-spin">
              <img
                src={IconLoading}
                alt="icon loading"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center">
              Silahkan Tunggu
            </h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative z-30"
        style={{ width: 512, height: 450 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>
        <h2 className="font-inter font-bold text-black2 text-center text-2xl mb-12">
          Jenis Vaksin
        </h2>

        <div className="border py-2 border-gray4 rounded-10 font-inter font-normal mb-4 w-full relative">
          <div className="flex justify-between pl-1 pr-4">
            <input
              type="text"
              name="status-antrian"
              className="focus:ring-0 outline-none border-0 text-sm text-black w-full rounded-10 placeholder-current"
              value={selectedVaksin?.brand_name || ''}
              placeholder="Silahkan Pilih Merek"
              autoComplete="off"
              readOnly
              onClick={() => setShowListJenisVaksin(!showListJenisVaksin)}
            />
            <img
              src={IconRightBlue}
              alt="right-arrow"
              className={`transform ${showListJenisVaksin ? 'rotate-90' : ''}`}
            />
          </div>

          <div className="absolute inset-x-0 top-16 z-20">
            {showListJenisVaksin && (
              <ul className="font-inter cursor-pointer bg-white border border-gray4 rounded-10 h-36 overflow-y-scroll">
                {listVaksin?.map((item) => (
                  <li
                    key={item.id}
                    className="flex items-center justify-between border-b border-gray2 px-7 py-3 hover:bg-blue2 focus-within:bg-blue1 text-sm text-black2"
                    onClick={(e) => {
                      setSelectedVaksin(item);
                      setShowListJenisVaksin(false);
                    }}
                  >
                    {item.brand_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="border py-2 border-gray4 rounded-10 font-inter font-normal mb-4 w-full relative">
          <div className="flex justify-between pl-1 pr-4">
            <input
              type="text"
              name="status-antrian"
              className="focus:ring-0 outline-none border-0 text-sm text-black w-full rounded-10 placeholder-current"
              value={
                selectedVaksin?.vaccine_types?.map(
                  (item, i) => `${item.name}`
                ) || ''
              }
              placeholder="Silahkan Jenis Vaksin"
              autoComplete="off"
              readOnly
            />
            <img
              src={IconRightBlue}
              alt="right-arrow"
              className={`transform ${showListJenisVaksin ? 'rotate-90' : ''}`}
            />
          </div>
        </div>

        <Button
          isPrimary
          title="Simpan"
          className="rounded-10"
          onClick={handleClickList}
        />
      </div>
    </div>
  );
}
